import {reportServices} from "../../service/reportService";
import {alertActions} from "./alertActions";
import {groupBy3Constants} from "../constants/groupBy3Constants";
import {
    allowToGenerateHash, generateMD5HashHex,
    GetSelectedGroupByPlaceholderFromTrafficSource,
    GetSelectedValue
} from "../../components/reporting-table/ReactDataGridUtils";
import {HasOnlyNumbersAndLetters, IsNumber} from "../../helpers/common";
import {integrationService} from "../../service/integrationService";
import ExportCSV from "../../components/export/ExportCSV";
import {v4 as uuidv4} from "uuid";

export const groupBy3Action = {
    getDetailsReport,
    updateOrderBy,
    updateSortBy,
    updateSortColumns,
    rerender,
    setSelectedRows,
    updateGroupBy,
    updateStatus,
    updateBid,
    updateBudget,
    reset
};

function getDetailsReport(params, infinityScroll = false) {
    return (dispatch, getState) => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
        dispatch(alertActions.error(""));

        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let column = getState().column.data;
        let groupBy1 = getState().groupBy1.groupBy?.groupBy;
        let groupBy2 = getState().groupBy2.groupBy?.groupBy;
        let groupBy3 = getState().groupBy3.groupBy?.groupBy;
        let groupBy1Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy1, trafficSource);
        let groupBy2Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy2, trafficSource);
        let groupBy3Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy3, trafficSource);

        let pushgroundCampaignId = null;
        if (groupBy1Placeholder === "{campaign_id}") {
            pushgroundCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
        } else if (groupBy2Placeholder === "{campaign_id}") {
            pushgroundCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
        }

        reportServices.getDetailsReport(params)
            .then(async (result) => {

                // CSV export
                if (params?.export === "csv") {
                    ExportCSV({fileName: "details_report_" + params?.from + "_to_" + params?.to, data: result});
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                    return false;
                }

                // Add hashed custom1-10 values if column hash is not hidden.
                if (allowToGenerateHash(groupBy3, column?.detailsReport)) {
                    for (const item of result?.records) {
                        item.hash = generateMD5HashHex(item?.name);
                    }
                }

                // Add UUID
                for (const item of result?.records) {
                    item.uuid = uuidv4();
                }

                // Add Pushground integration check.
                if (integration?.networkType === "pushground") {

                    let creativities = [];
                    if (groupBy3Placeholder === "{creativity_id}" && IsNumber(pushgroundCampaignId)) {
                        creativities = await integrationService.getPushgroundCampaignCreativities(integration?.id, pushgroundCampaignId);
                        if (creativities === null) {
                            creativities = [];
                        }
                    }

                    let suppliesAndSites = [];
                    let campaign = null;
                    if ((groupBy3Placeholder === "{supply_id}" || groupBy3Placeholder === "{site_id}") && IsNumber(pushgroundCampaignId)) {
                        campaign = await integrationService.findPushgroundCampaign(integration?.id, pushgroundCampaignId);
                        suppliesAndSites = await integrationService.getPushgroundCampaignSuppliesAndSitesData(integration?.id, pushgroundCampaignId);
                        if (suppliesAndSites?.blacklistedSupplies === null) {
                            suppliesAndSites.blacklistedSupplies = [];
                        }
                        if (suppliesAndSites?.blacklistedSites === null) {
                            suppliesAndSites.blacklistedSites = [];
                        }
                        if (suppliesAndSites?.supplyBids === null) {
                            suppliesAndSites.supplyBids = [];
                        }
                    }

                    for (const item of result?.records) {
                        let pushgroundId = item?.name;
                        if (pushgroundId !== "") {
                            // find Pushground campaign if group by placeholder is {campaign_id}
                            if (groupBy3Placeholder === "{campaign_id}" && IsNumber(pushgroundId)) {
                                let pResult = await integrationService.findPushgroundCampaign(integration?.id, pushgroundId);
                                if (pResult?.id > 0) {
                                    item.status = pResult?.active;
                                    item.bid = pResult?.bid;
                                }
                            }
                            // find Pushground campaign creativities if group by placeholder is {creativity_id}
                            if (groupBy3Placeholder === "{creativity_id}" && IsNumber(pushgroundId)) {
                                for (const c of creativities) {
                                    if (parseInt(c?.id) === parseInt(item?.name)) {
                                        item.status = c?.active;
                                    }
                                }
                            }
                            // find Pushground campaign supplies and sites blacklist if group by placeholder is {supply_id}
                            if (groupBy3Placeholder === "{supply_id}" && IsNumber(pushgroundId)) {
                                // Add default values
                                if (IsNumber(item?.name)) {
                                    item.status = true;
                                    if (campaign?.id > 0) {
                                        item.bid = campaign?.bid;
                                    }
                                }
                                // Add statuses
                                for (const supply of suppliesAndSites?.blacklistedSupplies) {
                                    if (parseInt(supply?.id) === parseInt(item?.name)) {
                                        item.status = supply?.active;
                                    }
                                }
                                // Add bids
                                for (const b of suppliesAndSites?.supplyBids) {
                                    if (parseInt(b?.id) === parseInt(item?.name)) {
                                        item.bid = b?.bid;
                                    }
                                }
                            }
                            // find Pushground campaign supplies and sites blacklist if group by placeholder is {site_id}
                            if (groupBy3Placeholder === "{site_id}") {
                                // Add default values
                                if (pushgroundId !== "" && pushgroundId !== "{site_id}") {
                                    item.status = true;
                                }
                                // Add statuses
                                for (const site of suppliesAndSites?.blacklistedSites) {
                                    if (site?.id === item?.name) {
                                        item.status = site?.active;
                                    }
                                }
                            }
                        }
                    }
                }

                // Add PropellerAds check
                if (integration?.networkType === "propeller-ads") {

                    let propellerAdsCampaignId = null;
                    if (groupBy1Placeholder === "{campaignid}") {
                        propellerAdsCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    } else if (groupBy2Placeholder === "{campaignid}") {
                        propellerAdsCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    }

                    // find PropellerAds campaign if group by 3 placeholder is {campaignid}
                    if (groupBy3Placeholder === "{campaignid}") {
                        let campaignIds = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (campaignIds === "") {
                                    campaignIds += item?.name;
                                } else {
                                    campaignIds += "," + item?.name;
                                }
                            }
                        }

                        // find PropellerAds campaigns if group by placeholder is {campaignid}
                        if (campaignIds !== "") {
                            let pResult = await integrationService.findPropellerAdsCampaigns({
                                integrationId: integration?.id, campaignIds: campaignIds
                            });

                            for (const item of result?.records) {
                                for (const pCampaign of pResult) {
                                    if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                        item.status = pCampaign?.active;
                                        item.bid = pCampaign?.bid;
                                        item.rateModel = pCampaign?.rateModel;
                                    }
                                }
                            }
                        }
                    }

                    // find PropellerAds zone if group by 3 placeholder is {zoneid}
                    if (groupBy3Placeholder === "{zoneid}" && propellerAdsCampaignId !== null) {
                        let zones = await integrationService.findPropellerAdsZoneRatesAndStatus({
                            integrationId: integration?.id,
                            campaignId: propellerAdsCampaignId
                        });
                        for (const item of result?.records) {
                            let propellerAdsId = item?.name;
                            // Add default values
                            if (propellerAdsId !== "" && propellerAdsId !== "{zoneid}") {
                                item.status = true;
                            }
                            // Set status false if includes
                            if (zones?.excludedZoneIDs.includes(parseInt(item?.name))) {
                                item.status = false;
                            }
                            // Add bid value
                            item.bid = zones?.campaignBid;
                            if (zones?.zoneRates !== null) {
                                for (const rate of zones?.zoneRates) {
                                    if (rate?.zoneId === propellerAdsId) {
                                        item.bid = rate?.bid;
                                    }
                                }
                            }
                        }
                    }

                    // find PropellerAds banner if group by 2 placeholder is {bannerid}
                    if (propellerAdsCampaignId !== null && groupBy3Placeholder === "{bannerid}") {
                        let banners = await integrationService.getPropellerAdsBanners({
                            integrationId: integration?.id,
                            campaignId: propellerAdsCampaignId
                        });
                        for (const item of result?.records) {
                            for (const banner of banners?.banners) {
                                if (parseInt(item?.name) === parseInt(banner?.id)) {
                                    item.status = banner.active;
                                    break;
                                }
                            }
                        }
                    }
                }

                // Add ExoClick check
                if (integration?.networkType === "exoclick") {

                    let campaignId = null;
                    if (groupBy1Placeholder === "{campaign_id}") {
                        campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    } else if (groupBy2Placeholder === "{campaign_id}") {
                        campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    }

                    // find ExoClick campaign if group by 3 placeholder is {campaign_id}
                    if (groupBy3Placeholder === "{campaign_id}") {
                        let campaignIds = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (campaignIds === "") {
                                    campaignIds += item?.name;
                                } else {
                                    campaignIds += "," + item?.name;
                                }
                            }
                        }

                        // find ExoClick campaigns if group by placeholder is {campaign_id}
                        if (campaignIds !== "") {
                            let pResult = await integrationService.findExoClickCampaigns({
                                integrationId: integration?.id, campaignIds: campaignIds
                            });

                            for (const item of result?.records) {
                                for (const pCampaign of pResult) {
                                    if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                        item.status = pCampaign?.active;
                                        item.bid = pCampaign?.bid;
                                        item.rateModel = pCampaign?.rateModel;
                                    }
                                }
                            }
                        }
                    }

                    // find ExoClick zone if group by 3 placeholder is {zone_id}
                    if (groupBy3Placeholder === "{zone_id}" && campaignId !== null) {
                        let zones = await integrationService.findExoClickZoneRatesAndStatus({
                            integrationId: integration?.id,
                            campaignId: campaignId
                        });
                        for (const item of result?.records) {
                            let exoClickId = item?.name;
                            // Add default values
                            if (exoClickId !== "" && exoClickId !== "{zone_id}") {
                                item.status = true;
                            }
                            // Set status false if includes
                            if (zones?.zoneIDs.includes(parseInt(item?.name))) {
                                item.status = false;
                            }
                            // Add default status false for targeted traffic
                            if (exoClickId !== "" && exoClickId !== "{zone_id}" && zones?.targetingType === "target") {
                                item.status = false;
                                // Set status false if includes
                                if (zones?.zoneIDs.includes(parseInt(item?.name))) {
                                    item.status = true;
                                }
                            }
                            // Add bid value
                            item.bid = zones?.campaignBid;
                            if (zones?.zoneRates !== null) {
                                for (const rate of zones?.zoneRates) {
                                    if (rate?.zoneId === exoClickId && rate?.bid !== null) {
                                        item.bid = rate?.bid;
                                    }
                                }
                            }
                        }
                    }

                    // find ExoClick banner if group by 3 placeholder is {variation_id}
                    if (groupBy3Placeholder === "{variation_id}" && campaignId !== null) {
                        let banners = await integrationService.getExoClickBanners({
                            integrationId: integration?.id,
                            campaignId: campaignId
                        });
                        for (const item of result?.records) {
                            for (const banner of banners?.banners) {
                                if (parseInt(item?.name) === parseInt(banner?.id)) {
                                    item.status = banner.active;
                                    break;
                                }
                            }
                        }
                    }

                    // find ExoClick sites if group by 2 placeholder is {site_id}
                    if (groupBy3Placeholder === "{site_id}" && campaignId !== null) {
                        let sites = await integrationService.getExoClickSiteStatuses({
                            integrationId: integration?.id,
                            campaignId: campaignId
                        });
                        for (const item of result?.records) {
                            if (sites?.targetingType === "block") {
                                if (item?.name !== "{zone_id}" && IsNumber(item?.name)) {
                                    item.status = true;
                                }
                                for (const site of sites?.siteIDs) {
                                    if (parseInt(item?.name) === parseInt(site)) {
                                        item.status = false;
                                        break;
                                    }
                                }
                            }
                            if (sites?.targetingType === "target") {
                                if (item?.name !== "{zone_id}" && IsNumber(item?.name)) {
                                    item.status = false;
                                }
                                for (const site of sites?.siteIDs) {
                                    if (parseInt(item?.name) === parseInt(site)) {
                                        item.status = true;
                                        break;
                                    }
                                }
                            }
                            if (sites?.targetingType === "all") {
                                if (item?.name !== "{zone_id}" && IsNumber(item?.name)) {
                                    item.status = true;
                                }
                            }
                        }
                    }
                }

                // Add Mondiad check
                if (integration?.networkType === "mondiad") {

                    let campaignId = null;
                    if (groupBy1Placeholder === "[campaignid]") {
                        campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    } else if (groupBy2Placeholder === "[campaignid]") {
                        campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    }

                    // find Mondiad campaign if group by 3 placeholder is [campaignid]
                    if (groupBy3Placeholder === "[campaignid]") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Mondiad campaigns if group by placeholder 3 is [campaignid]
                        if (ids !== "") {
                            try {
                                let pResult = await integrationService.findMondiadCampaigns({
                                    integrationId: integration?.id, campaignIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const pCampaign of pResult) {
                                        if (parseInt(pCampaign?.id) === parseInt(item?.name)) {
                                            item.status = pCampaign?.active;
                                            item.bid = pCampaign?.bid;
                                            item.rateModel = pCampaign?.rateModel;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    // find Mondiad zone if group by 3 placeholder is [zoneid]
                    if (groupBy3Placeholder === "[zoneid]" && campaignId !== null) {
                        try {
                            let zones = await integrationService.findMondiadZoneRatesAndStatus({
                                integrationId: integration?.id,
                                campaignId: campaignId
                            });
                            for (const item of result?.records) {
                                let mondiadId = item?.name;
                                // Add default values
                                if (mondiadId !== "" && mondiadId !== "[zoneid]") {
                                    item.status = true;
                                }
                                // Set status false if includes
                                if (zones?.zoneIDs.includes(parseInt(item?.name))) {
                                    item.status = false;
                                }
                                // Add default status false for whitelisted traffic
                                if (mondiadId !== "" && mondiadId !== "[zoneid]" && zones?.targetingType === "whitelist") {
                                    item.status = false;
                                    // Set status false if includes
                                    if (zones?.zoneIDs.includes(parseInt(item?.name))) {
                                        item.status = true;
                                    }
                                }
                                // Add bid value
                                item.bid = zones?.campaignBid;
                                item.rateModel = zones?.rateModel;
                                for (const rate of zones?.zoneRates) {
                                    if (parseInt(rate?.zoneId) === parseInt(mondiadId)) {
                                        item.bid = rate?.bid;
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    // find Mondiad creative if group by 3 placeholder is [creativeid]
                    if (groupBy3Placeholder === "[creativeid]") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        try {
                            let banners = await integrationService.findMondiadCreatives({
                                integrationId: integration?.id,
                                ids: ids
                            });
                            for (const item of result?.records) {
                                for (const creative of banners?.creatives) {
                                    if (parseInt(item?.name) === parseInt(creative?.id)) {
                                        item.status = creative.active;
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    // find Mondiad sites if group by 3 placeholder is [subid]
                    if (groupBy3Placeholder === "[subid]" && campaignId !== null) {
                        try {
                            let sites = await integrationService.findMondiadSiteRatesAndStatuses({
                                integrationId: integration?.id,
                                campaignId: campaignId
                            });
                            for (const item of result?.records) {
                                if (sites?.targetingType === "blacklist") {
                                    if (item?.name !== "[subid]" && HasOnlyNumbersAndLetters(item?.name)) {
                                        item.status = true;
                                    }
                                    for (const site of sites?.siteIDs) {
                                        if (item?.name === site) {
                                            item.status = false;
                                            break;
                                        }
                                    }
                                }
                                if (sites?.targetingType === "whitelist") {
                                    if (item?.name !== "[subid]" && HasOnlyNumbersAndLetters(item?.name)) {
                                        item.status = false;
                                    }
                                    for (const site of sites?.siteIDs) {
                                        if (item?.name === site) {
                                            item.status = true;
                                            break;
                                        }
                                    }
                                }

                                // Add bid value
                                item.bid = sites?.campaignBid;
                                item.rateModel = sites?.rateModel;
                                for (const rate of sites?.siteRates) {
                                    if (rate?.siteId === item?.name) {
                                        item.bid = rate?.bid;
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Google Ads check
                if (integration?.networkType === "google") {

                    // find Google Ads campaigns if group by 3 placeholder is {campaignid}
                    if (groupBy3Placeholder === "{campaignid}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Google Ads campaigns if group by placeholder 3 is {campaignid}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findGoogleAdsCampaigns({
                                    integrationId: integration?.id, campaignIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{adgroupid}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Google Ads ad groups if group by placeholder 3 is {adgroupid}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findGoogleAdsAdGroups({
                                    integrationId: integration?.id, adGroupIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{creative}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Google Ads ad group ads if group by placeholder 3 is {creative}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findGoogleAdsAdGroupAds({
                                    integrationId: integration?.id, adIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            item.trafficSourceAdGroupId = i?.adGroupId;
                                            if (i?.status === "ENABLED" || i?.status === "PAUSED") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }
                }

                // Add Microsoft Ads check
                if (integration?.networkType === "microsoft") {

                    let campaignId = null;
                    if (groupBy1Placeholder === "{CampaignId}") {
                        campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    } else if (groupBy2Placeholder === "{CampaignId}") {
                        campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    }

                    let adGroupId = null;
                    if (groupBy2Placeholder === "{AdGroupId}") {
                        adGroupId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    } else if (groupBy1Placeholder === "{AdGroupId}") {
                        adGroupId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    }

                    // find Microsoft Ads campaigns if group by 3 placeholder is {CampaignId}
                    if (groupBy3Placeholder === "{CampaignId}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Microsoft Ads campaigns if group by placeholder 3 is {CampaignId}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMicrosoftAdsCampaigns({
                                    integrationId: integration?.id, campaignIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            if (i?.status === "Active" || i?.status === "Paused") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{AdGroupId}" && campaignId !== null) {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Microsoft Ads ad groups if group by placeholder 3 is {AdGroupId}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMicrosoftAdsAdGroups({
                                    integrationId: integration?.id, adGroupIds: ids, campaignId: campaignId
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            if (i?.status === "Active" || i?.status === "Paused") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{AdId}" && adGroupId !== null) {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        // find Microsoft Ads ad group ads if group by placeholder 3 is {AdId}
                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMicrosoftAdsAdGroupAds({
                                    integrationId: integration?.id, adIds: ids, adGroupId: adGroupId
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            item.trafficSourceAdGroupId = i?.adGroupId;
                                            if (i?.status === "Active" || i?.status === "Paused") {
                                                item.status = i?.active;
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }
                }

                // Add Facebook Ads integration check.
                if (integration?.networkType === "facebook") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    // find Facebook Ads campaign, ad set and ad titles.
                    if (ids !== "" && ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupBy3Placeholder) !== -1) {

                        let level = "campaign";
                        if (groupBy3Placeholder === "{{adset.id}}") {
                            level = "adset";
                        }
                        if (groupBy3Placeholder === "{{ad.id}}") {
                            level = "ad";
                        }

                        try {
                            let iResult = await integrationService.findFacebookMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: ids,
                                dateStart: params?.from,
                                dateStop: params?.to
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (i?.campaignId === item?.name) {
                                            item.trafficSourceName = i?.campaignName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (i?.adSetId === item?.name) {
                                            item.trafficSourceName = i?.adSetName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (i?.adId === item?.name) {
                                            item.trafficSourceName = i?.adName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.campaigns?.length > 0) {
                                    for (const c of iResult?.campaigns) {
                                        if (c?.id === item?.name) {
                                            item.trafficSourceName = c?.name;
                                            item.status = c?.status;
                                            item.fbDailyBudget = c?.dailyBudget;
                                            item.deliveryStatus = c?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.adSets?.length > 0) {
                                    for (const adSet of iResult?.adSets) {
                                        if (adSet?.id === item?.name) {
                                            item.trafficSourceName = adSet?.name;
                                            item.status = adSet?.status;
                                            item.fbDailyBudget = adSet?.dailyBudget;
                                            item.deliveryStatus = adSet?.deliveryStatus;
                                            if (adSet?.bidStrategy === "LOWEST_COST_WITH_BID_CAP" || adSet?.bidStrategy === "COST_CAP") {
                                                item.bid = adSet?.bidAmount;
                                                item.rateModel = "Bid";
                                            }
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.ads?.length > 0) {
                                    for (const ad of iResult?.ads) {
                                        if (ad?.id === item?.name) {
                                            item.trafficSourceName = ad?.name;
                                            item.status = ad?.status;
                                            item.fbDailyBudget = ad?.dailyBudget;
                                            item.deliveryStatus = ad?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    // Find Facebook Ads stats when group by 3 is Date
                    let facebookId = null;
                    let level = "campaign";

                    if (["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupBy2Placeholder) !== -1) {
                        facebookId = GetSelectedValue(getState().groupBy2?.selectedRows);
                        if (groupBy2Placeholder === "{{adset.id}}") {
                            level = "adset";
                        } else if (groupBy2Placeholder === "{{ad.id}}") {
                            level = "ad";
                        }
                    } else if (["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupBy1Placeholder) !== -1) {
                        facebookId = GetSelectedValue(getState().groupBy1?.selectedRows);
                        if (groupBy1Placeholder === "{{adset.id}}") {
                            level = "adset";
                        } else if (groupBy1Placeholder === "{{ad.id}}") {
                            level = "ad";
                        }
                    }

                    if (facebookId !== null && groupBy3 === "date") {
                        try {
                            let iResult = await integrationService.findFacebookMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: facebookId,
                                dateStart: params?.from,
                                dateStop: params?.to,
                                breakdownByDay: "1"
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (i?.dateStart === item?.name) {
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Taboola integration check.
                if (integration?.networkType === "taboola") {
                    // find Taboola campaigns if group by 3 placeholder is {campaign_id}
                    if (groupBy3Placeholder === "{campaign_id}") {
                        try {
                            let iResult = await integrationService.findTaboolaCampaigns({
                                integrationId: integration?.id
                            });

                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.name;
                                        item.status = i?.active;
                                        item.deliveryStatus = i?.status;
                                        item.fbDailyBudget = i?.dailyBudget;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    if ((groupBy1Placeholder === "{campaign_id}" || groupBy2Placeholder === "{campaign_id}") && groupBy3Placeholder === "{campaign_item_id}") {
                        let campaignId = null;
                        if (groupBy1Placeholder === "{campaign_id}") {
                            campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                        } else if (groupBy2Placeholder === "{campaign_id}") {
                            campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                        }
                        // find Google Ads ad group ads if group by placeholder 3 is {campaign_item_id}
                        try {
                            let iResult = await integrationService.findTaboolaCampaignAds({
                                integrationId: integration?.id, campaignId: campaignId
                            });

                            for (const item of result?.records) {
                                for (const i of iResult) {
                                    if (parseInt(i?.id) === parseInt(item?.name)) {
                                        item.trafficSourceName = i?.title;
                                        if (i?.status === "RUNNING" || i?.status === "PAUSED" || i?.status === "PENDING_APPROVAL") {
                                            item.status = i?.active;
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    if ((groupBy1Placeholder === "{campaign_id}" || groupBy2Placeholder === "{campaign_id}") && groupBy3Placeholder === "{site}") {
                        let campaignId = null;
                        if (groupBy1Placeholder === "{campaign_id}") {
                            campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                        } else if (groupBy2Placeholder === "{campaign_id}") {
                            campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                        }
                        // find Google Ads ad group ads if group by placeholder 3 is {campaign_item_id}
                        try {
                            let iResult = await integrationService.findTaboolaCampaign({
                                integrationId: integration?.id, campaignId: campaignId
                            });

                            for (const item of result?.records) {
                                item.status = true;
                                // Set bid
                                if ((iResult?.bid_strategy === "SMART" || iResult?.bid_strategy === "FIXED") && iResult?.cpc !== null) {
                                    item.bid = iResult?.cpc;
                                    for (const i of iResult?.publisher_bid_modifier?.values) {
                                        if (i?.target === item?.name) {
                                            item.bid = i?.cpc_modification;
                                        }
                                    }
                                }
                                // Set site status
                                if (iResult?.publisher_targeting.value.includes(item?.name)) {
                                    item.status = false;
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add MGID integration check.
                if (integration?.networkType === "mgid") {

                    let campaignId = null;
                    if (groupBy1Placeholder === "{campaign_id}") {
                        campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
                    } else if (groupBy2Placeholder === "{campaign_id}") {
                        campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
                    }

                    if (groupBy3Placeholder === "{campaign_id}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMgidCampaigns({
                                    integrationId: integration?.id, campaignIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.name;
                                            item.status = i?.active;
                                            item.deliveryStatus = i?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{teaser_id}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^\d+$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findMgidAds({
                                    integrationId: integration?.id, adIds: ids
                                });
                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (parseInt(i?.id) === parseInt(item?.name)) {
                                            item.status = i?.active;
                                            item.deliveryStatus = i?.deliveryStatus
                                            let bidNum = i?.bid / 100;
                                            item.bid = bidNum.toFixed(4);
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (campaignId !== null && IsNumber(campaignId) && groupBy3Placeholder === "{widget_id}") {
                        try {
                            let iResult = await integrationService.findMgidWidgets({
                                integrationId: integration?.id, campaignId: campaignId
                            });

                            for (const item of result?.records) {
                                if (iResult?.filterType === "off") {
                                    item.status = true;
                                } else if (iResult?.filterType === "except") {
                                    item.status = !iResult?.widgets?.includes(item?.name);
                                } else if (iResult?.filterType === "only") {
                                    if (iResult?.widgets?.includes(item?.name)) {
                                        item.status = true;
                                    } else {
                                        item.status = false;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add Outbrain Ads check
                if (integration?.networkType === "outbrain") {

                    const outbrainRegex = /^[a-z0-9]{34}$/

                    let campaignId = null;
                    if (groupBy1Placeholder === "{{campaign_id}}") {
                        let groupBy1Value = GetSelectedValue(getState().groupBy1?.selectedRows);
                        if (groupBy1Value?.match(outbrainRegex) !== null) {
                            campaignId = groupBy1Value;
                        }
                    } else if (groupBy2Placeholder === "{{campaign_id}}") {
                        let groupBy2Value = GetSelectedValue(getState().groupBy2?.selectedRows);
                        if (groupBy2Value?.match(outbrainRegex) !== null) {
                            campaignId = groupBy2Value;
                        }
                    }

                    if (groupBy3Placeholder === "{{campaign_id}}") {
                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^[a-z0-9]{34}$/) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findOutbrainCampaigns({
                                    integrationId: integration?.id,
                                    campaignIds: ids
                                });

                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (i?.id === item?.name) {
                                            item.trafficSourceName = i?.name;
                                            item.status = i?.status;
                                            item.deliveryStatus = i?.deliveryStatus;
                                            item.bid = i?.bid;
                                            item.bidRate = i?.bidRate;
                                            item.budgetId = i?.budget?.id;
                                            item.fbDailyBudget = i?.budget?.amount;
                                            item.fbAccountCurrency = i?.budget?.currency;
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{{ad_id}}" && campaignId !== null) {

                        let ids = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(outbrainRegex) !== null) {
                                if (ids === "") {
                                    ids += item?.name;
                                } else {
                                    ids += "," + item?.name;
                                }
                            }
                        }

                        if (ids !== "") {
                            try {
                                let iResult = await integrationService.findOutbrainAds({
                                    integrationId: integration?.id, campaignId: campaignId
                                });
                                for (const item of result?.records) {
                                    for (const i of iResult) {
                                        if (i?.id === item?.name) {
                                            item.trafficSourceName = i?.name;
                                            item.status = i?.active;
                                            item.deliveryStatus = i?.deliveryStatus
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }
                    }

                    if (groupBy3Placeholder === "{{section_id}}" && campaignId !== null) {

                        let sectionIds = "";
                        for (const item of result?.records) {
                            if (item?.name?.match(/^[a-z0-9]{34}$/) !== null) {
                                if (sectionIds === "") {
                                    sectionIds += item?.name;
                                } else {
                                    sectionIds += "," + item?.name;
                                }
                            }
                        }

                        if (sectionIds?.length > 0) {
                            try {
                                let iSections = await integrationService.findOutbrainSections({
                                    integrationId: integration?.id, sectionIds: sectionIds
                                });
                                for (const item of result?.records) {
                                    if (item?.name?.match(outbrainRegex) === null) {
                                        // Skip
                                        continue;
                                    }
                                    // Add section name
                                    for (const oSection of iSections?.sections) {
                                        if (oSection?.id === item?.name) {
                                            item.trafficSourceName = oSection?.name;
                                            break;
                                        }
                                    }
                                }
                            } catch (e) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            }
                        }

                        try {
                            let iResult = await integrationService.findOutbrainCampaigns({
                                integrationId: integration?.id, campaignIds: campaignId
                            });
                            for (const item of result?.records) {
                                if (item?.name?.match(outbrainRegex) === null) {
                                    // Skip
                                    continue;
                                }
                                item.status = true;
                                item.deliveryStatus = "Active";
                                for (const oCampaign of iResult) {
                                    if (oCampaign?.id === campaignId) {

                                        if (oCampaign?.allowBidsBySection) {
                                            item.adjustCpcPercentage = 0;
                                            // Edit section bid value
                                            if (oCampaign?.bidsBySection?.length > 0) {
                                                for (const bidBySec of oCampaign?.bidsBySection) {
                                                    if (bidBySec?.id === item?.name) {
                                                        item.adjustCpcPercentage = bidBySec?.bid * 100;
                                                        break;
                                                    }
                                                }
                                            }
                                        }

                                        // Edit section status
                                        if (oCampaign?.blockedSections?.includes(item?.name)) {
                                            item.status = false;
                                            item.deliveryStatus = "Paused";
                                            break;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }

                    if (groupBy3Placeholder === "{{publisher_id}}" && campaignId !== null) {
                        try {
                            let iResult = await integrationService.findOutbrainCampaigns({
                                integrationId: integration?.id, campaignIds: campaignId
                            });
                            for (const item of result?.records) {
                                if (item?.name?.match(outbrainRegex) === null) {
                                    // Skip
                                    continue;
                                }
                                item.status = true;
                                item.deliveryStatus = "Active";
                                for (const oCampaign of iResult) {
                                    if (oCampaign?.id === campaignId) {
                                        // Edit section status
                                        if (oCampaign?.blockedPublishers?.includes(item?.name)) {
                                            item.status = false;
                                            item.deliveryStatus = "Paused";
                                            break;
                                        }
                                        break;
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Add TikTok Ads integration check.
                if (integration?.networkType === "tiktok") {
                    let ids = "";
                    for (const item of result?.records) {
                        if (item?.name?.match(/^\d+$/) !== null) {
                            if (ids === "") {
                                ids += item?.name;
                            } else {
                                ids += "," + item?.name;
                            }
                        }
                    }

                    let tikTokPlaceholders = ["__CAMPAIGN_ID__", "__AID__", "__CID__"];

                    // Find TikTok campaign, adgroup and ad titles.
                    if (ids !== "" && tikTokPlaceholders.indexOf(groupBy3Placeholder) !== -1) {

                        let level = "campaign";
                        if (groupBy3Placeholder === "__AID__") {
                            level = "adgroup";
                        } else if (groupBy3Placeholder === "__CID__") {
                            level = "ad";
                        }

                        try {
                            let iResult = await integrationService.findTikTokMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: ids,
                                dateStart: params?.from,
                                dateStop: params?.to
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (parseInt(i?.campaignId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.campaignName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (parseInt(i?.adGroupId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.adGroupName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        } else if (parseInt(i?.adId) === parseInt(item?.name)) {
                                            item.trafficSourceName = i?.adName;
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.campaigns?.length > 0) {
                                    for (const c of iResult?.campaigns) {
                                        if (parseInt(c?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = c?.name;
                                            item.status = c?.status;
                                            if (c?.dailyBudget > 0) {
                                                item.fbDailyBudget = c?.dailyBudget;
                                            }
                                            item.deliveryStatus = c?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.adGroups?.length > 0) {
                                    for (const adGroup of iResult?.adGroups) {
                                        if (parseInt(adGroup?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = adGroup?.name;
                                            item.status = adGroup?.status;
                                            if (adGroup?.dailyBudget > 0) {
                                                item.fbDailyBudget = adGroup?.dailyBudget;
                                            }
                                            item.deliveryStatus = adGroup?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                                if (iResult?.ads?.length > 0) {
                                    for (const ad of iResult?.ads) {
                                        if (parseInt(ad?.id) === parseInt(item?.name)) {
                                            item.trafficSourceName = ad?.name;
                                            item.status = ad?.status;
                                            item.fbDailyBudget = ad?.dailyBudget;
                                            item.deliveryStatus = ad?.deliveryStatus;
                                            break;
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            if (e?.response?.data?.error) {
                                dispatch(alertActions.error(e?.response?.data?.error));
                            } else {
                                dispatch(alertActions.error("Unable to load TikTok metrics data."));
                            }
                        }
                    }

                    // Find TikTok stats when group by 3 is Date
                    let tikTokId = null;
                    let level = "campaign";

                    if (tikTokPlaceholders.indexOf(groupBy2Placeholder) !== -1) {
                        tikTokId = GetSelectedValue(getState().groupBy2?.selectedRows);
                        if (groupBy2Placeholder === "__AID__") {
                            level = "adgroup";
                        } else if (groupBy2Placeholder === "__CID__") {
                            level = "ad";
                        }
                    } else if (tikTokPlaceholders.indexOf(groupBy1Placeholder) !== -1) {
                        tikTokId = GetSelectedValue(getState().groupBy1?.selectedRows);
                        if (groupBy1Placeholder === "__AID__") {
                            level = "adgroup";
                        } else if (groupBy1Placeholder === "__CID__") {
                            level = "ad";
                        }
                    }

                    // Find TikTok stats when group by 3 is Date
                    if (tikTokId !== null && groupBy3 === "date") {

                        try {
                            let iResult = await integrationService.findTikTokMetricsAndOtherFields({
                                integrationId: integration?.id,
                                level: level,
                                ids: tikTokId,
                                dateStart: params?.from,
                                dateStop: params?.to,
                                breakdownByDay: "1"
                            });
                            for (const item of result?.records) {
                                if (iResult?.insights?.length > 0) {
                                    for (const i of iResult?.insights) {
                                        if (i?.dateStart?.slice(0, 10) === item?.name) {
                                            item.fbCpm = i?.cpm;
                                            item.fbImpressions = i?.impressions;
                                            item.fbLinkClicks = i?.linkClicks;
                                            item.fbLinkCpc = i?.linkCpc;
                                            item.fbCtr = i?.linkCtr;
                                            item.fbAccountCurrency = i?.accountCurrency;
                                            item.fbEpc = parseFloat(item?.revenue) / parseInt(i?.linkClicks);
                                        }
                                    }
                                }
                            }
                        } catch (e) {
                            dispatch(alertActions.error(e?.response?.data?.error));
                        }
                    }
                }

                // Set modified result.
                if (infinityScroll) {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_INFINITY_SCROLL, result});
                } else {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }
            }).catch((error) => {
                dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, error});
                if (error?.response?.data?.error) {
                    dispatch(alertActions.error(error?.response?.data?.error));
                } else {
                    dispatch(alertActions.error("Invalid request. Please change your Group By values."));
                }
            }
        );
    };
}

function updateOrderBy(orderBy) {
    return (dispatch) => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_UPDATE_ORDER_BY, orderBy});
    };
}

function updateSortBy(sortBy) {
    return (dispatch) => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_UPDATE_SORT_BY, sortBy});
    };
}

function updateSortColumns(sortColumns) {
    return (dispatch) => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_UPDATE_SORT_COLUMNS, sortColumns});
    };
}

function rerender() {
    return dispatch => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_RERENDER});
    };
}

function setSelectedRows(selectedUUIDs, selectedRows) {
    return dispatch => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_SET_SELECTED_ROW, payload: {selectedUUIDs, selectedRows}});
    };
}

function updateGroupBy(groupBy) {
    return dispatch => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_UPDATE_GROUP_BY, groupBy});
    };
}

function updateStatus(status, name) {
    return (dispatch, getState) => {

        let result = getState().groupBy3.data;
        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let groupBy1 = getState().groupBy1.groupBy?.groupBy;
        let groupBy2 = getState().groupBy2.groupBy?.groupBy;
        let groupBy3 = getState().groupBy3.groupBy?.groupBy;
        let groupBy1Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy1, trafficSource);
        let groupBy2Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy2, trafficSource);
        let groupBy3Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy3, trafficSource);

        let pushgroundCampaignId = null;
        if (groupBy1Placeholder === "{campaign_id}") {
            pushgroundCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
        } else if (groupBy2Placeholder === "{campaign_id}") {
            pushgroundCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
        }

        if (integration?.networkType === "pushground") {
            if (groupBy3Placeholder === "{campaign_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCampaignStatus(status, integration?.id, name).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{creativity_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCreativityStatus(status, integration?.id, name).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{supply_id}" && IsNumber(pushgroundCampaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCampaignSupplyAndSiteStatus(status, integration?.id, pushgroundCampaignId, name, null).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{site_id}" && IsNumber(pushgroundCampaignId)) {
                let supplySite = name.split(/-(.*)/s);
                let supplyId = supplySite[0];
                let siteId = supplySite[1];

                if (siteId === undefined || siteId === "" || siteId === null) {
                    dispatch(alertActions.error("Updating Site status failed. Invalid Site ID."));
                    return false;
                }

                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCampaignSupplyAndSiteStatus(status, integration?.id, pushgroundCampaignId, supplyId, siteId).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "propeller-ads") {

            let propellerAdsCampaignId = null;
            if (groupBy1Placeholder === "{campaignid}") {
                propellerAdsCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaignid}") {
                propellerAdsCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{zoneid}" && propellerAdsCampaignId !== null) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePropellerAdsZoneStatus({
                    status: status, integrationId: integration?.id, campaignId: propellerAdsCampaignId, zoneId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{campaignid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePropellerAdsCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{bannerid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePropellerAdsBannerStatus({
                    status,
                    integrationId: integration?.id,
                    bannerId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(response?.id)) {
                            i.status = response?.active;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "exoclick") {
            let campaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{campaign_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateExoClickCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{variation_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateExoClickBannerStatus({
                    campaignId: campaignId,
                    status,
                    integrationId: integration?.id,
                    bannerId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(response?.id)) {
                            i.status = response?.active;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{zone_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateExoClickZoneStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, zoneId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{site_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateExoClickSiteStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, siteId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "mondiad") {

            let campaignId = null;
            if (groupBy1Placeholder === "[campaignid]") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "[campaignid]") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "[campaignid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "[creativeid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadCreativeStatus({
                    status,
                    integrationId: integration?.id,
                    creativeId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(response?.id)) {
                            i.status = response?.active;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "[zoneid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadZoneStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, zoneId: parseInt(name)
                }).then(() => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(name)) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "[subid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadSiteStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, siteId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "google") {
            if (groupBy3Placeholder === "{campaignid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateGoogleAdsCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{adgroupid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateGoogleAdsAdGroupStatus({
                    status: status, integrationId: integration?.id, adGroupId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{creative}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                let adGroupId = 0;
                for (let i of result?.records) {
                    if (i?.trafficSourceAdGroupId > 0) {
                        adGroupId = i?.trafficSourceAdGroupId;
                        break;
                    }
                }

                integrationService.updateGoogleAdsAdGroupAdStatus({
                    status: status, integrationId: integration?.id, adGroupId, adId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "microsoft") {

            let campaignId = null;
            if (groupBy1Placeholder === "{CampaignId}") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{CampaignId}") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            let adGroupId = null;
            if (groupBy2Placeholder === "{AdGroupId}") {
                adGroupId = GetSelectedValue(getState().groupBy2?.selectedRows);
            } else if (groupBy1Placeholder === "{AdGroupId}") {
                adGroupId = GetSelectedValue(getState().groupBy1?.selectedRows);
            }

            if (groupBy3Placeholder === "{CampaignId}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMicrosoftAdsCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{AdGroupId}" && IsNumber(campaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMicrosoftAdsAdGroupStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, adGroupId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{AdId}" && IsNumber(adGroupId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                // Get Microsoft Ads Type
                let adType = ""
                for (let i of result?.records) {
                    if (i?.name === name) {
                        adType = i?.trafficSourceName;
                        break;
                    }
                }

                integrationService.updateMicrosoftAdsAdStatus({
                    status: status, integrationId: integration?.id, adId: name, adGroupId: adGroupId, adType: adType
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "taboola") {

            let campaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{campaign_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTaboolaCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{campaign_item_id}" && IsNumber(campaignId) && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTaboolaCampaignAdStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, adId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{site}" && IsNumber(campaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTaboolaCampaignSiteStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, site: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "facebook") {
            let fbPlaceholders = ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"]
            if (fbPlaceholders.indexOf(groupBy3Placeholder) !== -1) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateFacebookStatus({
                    status: status, integrationId: integration?.id, id: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (response?.success === true && status) {
                                i.deliveryStatus = "Active"
                            } else {
                                i.deliveryStatus = "Paused"
                            }
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }

        if (integration?.networkType === "mgid") {

            let campaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{campaign_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMgidCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            i.deliveryStatus = response?.deliveryStatus;
                            break;
                        }
                    }
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{teaser_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMgidAdStatus({
                    status: status, integrationId: integration?.id, adId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (status) {
                                i.deliveryStatus = "Active";
                            } else {
                                i.deliveryStatus = "Paused";
                            }
                            break;
                        }
                    }
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{widget_id}" && IsNumber(campaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMgidWidgetStatus({
                    status: status, integrationId: integration?.id, campaignId: campaignId, widgetId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "outbrain") {

            const outbrainRegex = /^[a-z0-9]{34}$/

            let campaignId = null;
            if (groupBy1Placeholder === "{{campaign_id}}") {
                let groupBy1Value = GetSelectedValue(getState().groupBy1?.selectedRows);
                if (groupBy1Value?.match(outbrainRegex) !== null) {
                    campaignId = groupBy1Value;
                }
            } else if (groupBy2Placeholder === "{{campaign_id}}") {
                let groupBy2Value = GetSelectedValue(getState().groupBy2?.selectedRows);
                if (groupBy2Value?.match(outbrainRegex) !== null) {
                    campaignId = groupBy2Value;
                }
            }

            if (groupBy3Placeholder === "{{campaign_id}}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateOutbrainCampaignStatus({
                    status: status, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{{ad_id}}" && campaignId !== null) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                integrationService.updateOutbrainAdStatus({
                    status: status, integrationId: integration?.id, adId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (!status) {
                                i.deliveryStatus = "Paused"
                            } else {
                                i.deliveryStatus = "Active"
                            }
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{{publisher_id}}" && campaignId !== null) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                integrationService.updateOutbrainPublisherStatus({
                    status: status, integrationId: integration?.id, publisherId: name, campaignId: campaignId
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (!status) {
                                i.deliveryStatus = "Paused"
                            } else {
                                i.deliveryStatus = "Active"
                            }
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }

            if (groupBy3Placeholder === "{{section_id}}" && campaignId !== null) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                integrationService.updateOutbrainSectionStatus({
                    status: status, integrationId: integration?.id, sectionId: name, campaignId: campaignId
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (!status) {
                                i.deliveryStatus = "Paused"
                            } else {
                                i.deliveryStatus = "Active"
                            }
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "tiktok") {
            let fbPlaceholders = ["__CAMPAIGN_ID__", "__AID__", "__CID__"]

            let level = "campaign";
            if (groupBy3Placeholder === "__AID__") {
                level = "adgroup";
            } else if (groupBy3Placeholder === "__CID__") {
                level = "ad"
            }

            if (fbPlaceholders.indexOf(groupBy3Placeholder) !== -1) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTikTokStatus({
                    status: status, level: level, integrationId: integration?.id, id: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.status = status;
                            i.uuid = uuidv4();
                            if (response?.success === true && status) {
                                i.deliveryStatus = "Active"
                            } else {
                                i.deliveryStatus = "Inactive"
                            }
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }
    };
}

function updateBid(bid, name) {
    return (dispatch, getState) => {

        let result = getState().groupBy3.data;
        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let groupBy1 = getState().groupBy1.groupBy?.groupBy;
        let groupBy2 = getState().groupBy2.groupBy?.groupBy;
        let groupBy3 = getState().groupBy3.groupBy?.groupBy;
        let groupBy1Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy1, trafficSource);
        let groupBy2Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy2, trafficSource);
        let groupBy3Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy3, trafficSource);

        if (integration?.networkType === "pushground") {

            let pushgroundCampaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                pushgroundCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                pushgroundCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{campaign_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCampaignBid(bid, integration?.id, name).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{supply_id}" && IsNumber(pushgroundCampaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePushgroundCampaignSupplyBid({
                    bid, integrationId: integration?.id, supplyId: name, pushgroundCampaignId
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "propeller-ads") {

            let propellerAdsCampaignId = null;
            if (groupBy1Placeholder === "{campaignid}") {
                propellerAdsCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaignid}") {
                propellerAdsCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{zoneid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                integrationService.updatePropellerAdsZoneBid({
                    bid, integrationId: integration?.id, campaignId: propellerAdsCampaignId, zoneId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }

            if (groupBy3Placeholder === "{campaignid}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updatePropellerAdsCampaignBid({
                    bid: bid, integrationId: integration?.id, campaignId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "exoclick") {

            let campaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{campaign_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateExoClickCampaignBid({
                    bid: bid, integrationId: integration?.id, campaignId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === campaignId) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "{zone_id}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));

                integrationService.updateExoClickZoneBid({
                    bid, integrationId: integration?.id, campaignId: campaignId, zoneId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "mondiad") {

            let campaignId = null;
            if (groupBy1Placeholder === "[campaignid]") {
                campaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "[campaignid]") {
                campaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "[campaignid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadCampaignBid({
                    bid: bid, integrationId: integration?.id, campaignId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === campaignId) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "[zoneid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadZoneBid({
                    bid, integrationId: integration?.id, campaignId: campaignId, zoneId: parseInt(name)
                }).then((response) => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(name)) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
            if (groupBy3Placeholder === "[subid]") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMondiadSiteBid({
                    bid, integrationId: integration?.id, campaignId: campaignId, siteId: name
                }).then((response) => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = response?.bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "taboola") {

            let extCampaignId = null;
            if (groupBy1Placeholder === "{campaign_id}") {
                extCampaignId = GetSelectedValue(getState().groupBy1?.selectedRows);
            } else if (groupBy2Placeholder === "{campaign_id}") {
                extCampaignId = GetSelectedValue(getState().groupBy2?.selectedRows);
            }

            if (groupBy3Placeholder === "{site}" && IsNumber(extCampaignId)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTaboolaCampaignSiteBid(
                    {integrationId: integration?.id, campaignId: extCampaignId, site: name, bid: bid}
                ).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = bid;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "mgid") {
            if (groupBy3Placeholder === "{teaser_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateMgidAdBid({
                    bid: bid, integrationId: integration?.id, adId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = bid;
                            i.uuid = uuidv4();
                        }
                    }
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }

        if (integration?.networkType === "outbrain") {

            const outbrainRegex = /^[a-z0-9]{34}$/
            let campaignId = null;
            if (groupBy1Placeholder === "{{campaign_id}}") {
                let groupBy1Value = GetSelectedValue(getState().groupBy1?.selectedRows);
                if (groupBy1Value?.match(outbrainRegex) !== null) {
                    campaignId = groupBy1Value;
                }
            } else if (groupBy2Placeholder === "{{campaign_id}}") {
                let groupBy2Value = GetSelectedValue(getState().groupBy2?.selectedRows);
                if (groupBy2Value?.match(outbrainRegex) !== null) {
                    campaignId = groupBy2Value;
                }
            }

            if (groupBy3Placeholder === "{{campaign_id}}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateOutbrainCampaignBid({
                    bid: bid, integrationId: integration?.id, campaignId: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.bid = bid;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }

            if (groupBy3Placeholder === "{{section_id}}" && campaignId !== null) {

                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateOutbrainSectionBid({
                    bid: bid, integrationId: integration?.id, sectionId: name, campaignId: campaignId
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.adjustCpcPercentage = bid;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                })
            }
        }

        if (integration?.networkType === "facebook") {
            if (bid > 0 && ["{{adset.id}}"].indexOf(groupBy3Placeholder) !== -1) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateFacebookAdSetBid({
                    bid: bid, integrationId: integration?.id, id: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(name)) {
                            i.uuid = uuidv4();
                            i.bid = parseFloat(bid);
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }
    };
}

function updateBudget(amount, name, budgetId) {
    return (dispatch, getState) => {

        let result = getState().groupBy3.data;
        let integration = getState().groupBy1.integration;
        let trafficSource = getState().groupBy1.trafficSource;
        let groupBy3 = getState().groupBy3.groupBy?.groupBy;
        let groupBy3Placeholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy3, trafficSource);

        if (integration?.networkType === "facebook") {
            if (amount > 0 && ["{{campaign.id}}", "{{adset.id}}", "{{ad.id}}"].indexOf(groupBy3Placeholder) !== -1) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateFacebookDailyBudget({
                    dailyBudget: amount, integrationId: integration?.id, id: name
                }).then(() => {
                    for (let i of result?.records) {
                        if (parseInt(i?.name) === parseInt(name)) {
                            i.fbDailyBudget = amount;
                            i.uuid = uuidv4();
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }

        if (integration?.networkType === "tiktok") {
            let ttPlaceholders = ["__CAMPAIGN_ID__", "__AID__"]
            let level = "campaign";
            if (groupBy3Placeholder === "__AID__") {
                level = "adgroup";
            }

            if (amount > 0 && ttPlaceholders.indexOf(groupBy3Placeholder) !== -1) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTikTokDailyBudget({
                    dailyBudget: amount, level: level, integrationId: integration?.id, id: name
                }).then((response) => {
                    if (!response?.success) {
                        dispatch(alertActions.error(response?.message));
                        dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                    } else {
                        for (let i of result?.records) {
                            if (i?.name === name) {
                                i.fbDailyBudget = amount;
                                i.uuid = uuidv4();
                                break;
                            }
                        }
                        dispatch(alertActions.success("Updated successfully"));
                        dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                    }
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }

        if (integration?.networkType === "outbrain") {
            if (amount > 0 && groupBy3Placeholder === "{{campaign_id}}") {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateOutbrainCampaignBudget({
                    amount: amount, integrationId: integration?.id, budgetId: budgetId
                }).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.fbDailyBudget = amount;
                            i.uuid = uuidv4();
                            break;
                        }
                    }
                    // Set modified result.
                    dispatch(alertActions.success("Updated successfully"));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch(alertActions.error(e?.response?.data?.error));
                    dispatch({type: groupBy3Constants.GROUP_BY_3_STOP_LOADING_SPINNER});
                });
            }
        }

        if (integration?.networkType === "taboola") {
            if (groupBy3Placeholder === "{campaign_id}" && IsNumber(name)) {
                dispatch({type: groupBy3Constants.GROUP_BY_3_LOADING_SPINNER});
                dispatch(alertActions.error(""));
                integrationService.updateTaboolaCampaignDailyBudget(
                    {amount: amount, integrationId: integration?.id, campaignId: name}
                ).then(() => {
                    for (let i of result?.records) {
                        if (i?.name === name) {
                            i.uuid = uuidv4();
                            i.fbDailyBudget = amount;
                        }
                    }
                    // Set modified result.
                    dispatch({type: groupBy3Constants.GROUP_BY_3_GET_DATA, result});
                }).catch((e) => {
                    dispatch({type: groupBy3Constants.GROUP_BY_3_ERROR, e});
                    dispatch(alertActions.error(e?.response?.data?.error));
                })
            }
        }
    };
}

function reset() {
    return dispatch => {
        dispatch({type: groupBy3Constants.GROUP_BY_3_RESET});
    };
}